






body {	
    margin: 0;
    padding: 0;
    background: black;
    
    color: rgb(50,50,50);
    font-family: 'Open Sans', sans-serif;
    font-size: 112.5%;
    line-height: 1.6em;
  }
  
  /* ================ The Timeline ================ */
  
  .timeline {
    font-family: 'Pathway Gothic One', sans-serif;
    position: relative;
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
  }
  
  .timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(80,80,80);
    background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    
    z-index: 5;
  }
  
  .timeline li {
    padding: 1em 0;
  }
  
  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  
  .direction-l {
    position: relative;
    width: 47.6%;
    font-size: 32px;
    float: left;
    text-align: right;
    
    z-index: 15;
    opacity: 0;
    transform: translateY(4em) rotateZ(20deg);
    transition: transform 3s 0.25s cubic-bezier(0, 1, 0.3, 1),
      opacity 0.3s 0.25s ease-out;
    will-change: transform, opacity;
  }
  
  .direction-r {
    position: relative;
    font-size: 32px;
    width: 47.6%;
    margin-left: 5px;
    opacity: 0;
    float: right;
    z-index: 15;
    transform: translateY(4em) rotateZ(-20deg);
    transition: transform 3s 0.25s cubic-bezier(0, 1, 0.3, 1),
      opacity 0.3s 0.25s ease-out;
    will-change: transform, opacity;
  }
  .direction-r.is-visible {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  .direction-l.is-visible {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  .flag-wrapper {
    position: relative;
    display: inline-block;
    
    text-align: center;
  }
  .red
  {
    color: red;
  }
  .flag {
    position: relative;
    display: inline;
    border: 1px solid white;
    font-size: 30px;
    padding: 6px 10px;
    border-radius: 5px;
    color: rgb(230, 165, 26);
    font-weight: 600;
    text-align: left;
  }
  
  .direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -40px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: rgb(0, 0, 0);
    border-radius: 10px;
    border: 4px solid rgb(255, 255, 255);
    z-index: 10;
  }
  
  .direction-r .flag:before {
    left: -40px;
  }
  
  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .time-wrapper {
    display: inline;
    
    line-height: 1em;
    font-size: 0.66666em;
    color: rgb(250,80,80);
    vertical-align: middle;
  }
  
  .direction-l .time-wrapper {
    float: left;
  }
  
  .direction-r .time-wrapper {
    float: right;
  }
  
  .time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
  }
  p{
      margin: 10px;
      padding: 10px;
  }
  
  .desc {
    margin: 1em 0.75em 0 0;
    border-radius: 50%;

  box-shadow:
     /* inner right cyan broad */
    0 0 50px #fff,            /* outer white */
    -10px 0 80px #f0f,        /* outer left magenta */
    10px 0 80px #0ff;   
    color: white;
    font-size: 0.77777em;
    text-align: center;
    border-radius: 20px;
    font-style: italic;
    line-height: 1.5em;
    
  }
  
  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
  
  /* ================ Timeline Media Queries ================ */

  @media screen and (max-width: 1515px) {
  

    .direction-l {
      position: relative;
      width: 46.9%;
      font-size: 32px;
      float: left;
      text-align: right;
    }
    
    .direction-r {
      position: relative;
      width: 46.9%;
      margin-left: 5px;
      font-size: 32px;
      float: right;
      
    }
  }
  @media screen and (max-width: 1211px) {
    .direction-l {
      position: relative;
      width: 46.9%;
      font-size: 32px;
      float: left;
      text-align: right;
    }
    
    .direction-r {
      position: relative;
      width: 46.9%;
      margin-left: 5px;
      font-size: 32px;
      float: right;
      
    }
  .timeline {
       width: 100%;
      padding: 4em 0 1em 0;
  }
  
  .timeline li {
      padding: 2em 0;
  }
  
  .direction-l,
  .direction-r {
      float: none;
      width: 99%;
  
      text-align: center;
  }
  
  .flag-wrapper {
      text-align: center;
  }
  
  .flag {
      background: rgb(255,255,255);
      z-index: 15;
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
      left: 50%;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: -9px;
      background: rgb(0, 0, 0);
      border-radius: 10px;
      border: 4px solid rgb(255, 255, 255);
      z-index: 10;
  }
  
  .direction-l .flag:after,
  .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color: rgb(255,255,255);
      border-width: 8px;
      pointer-events: none;
  }
  
  .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
  }
  
  .direction-l .time-wrapper {
      float: none;
  }
  
  .direction-r .time-wrapper {
      float: none;
  }
  
  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;
      box-shadow:
      inset 0 0 50px #fff,      /* inner white */
      inset 20px 0 80px #f0f,   /* inner left magenta short */
      inset -20px 0 80px #0ff,  /* inner right cyan short */
      inset 20px 0 300px #f0f,  /* inner left magenta broad */
      inset -20px 0 300px #0ff, /* inner right cyan broad */
      0 0 50px #fff,            /* outer white */
      -10px 0 80px #f0f,        /* outer left magenta */
      10px 0 80px #0ff; 
    
    z-index: 15;
  }
  
  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }
  
  }
  
  @media screen and (min-width: 400px ?? max-width: 660px) {
  
  .direction-l .desc,
  .direction-r .desc {
      margin: 1em 4em 0 4em;
  }
  
  }
