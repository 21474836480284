






body {	
    margin: 0;
    padding: 0;
    background: black;
    
    color: rgb(50,50,50);
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 112.5%;
    line-height: 1.6em;
  }
  .outroad
  {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  /* ================ The Timeline ================ */
  
  
  .direction-l {
    position: relative;
    width: 47.6%;
    font-size: 32px;
    float: left;
    text-align: right;
    
   
  }
  

  
  .fla-wrapper {
    position: relative;
    
    text-align: center;
  }
  .red
  {
    color: red;
  }
  .fla {
    position: relative;
    display: inline;
    font-size: 45px;
    
    border: 2px solid white;
    padding: 6px 10px;
    border-radius: 5px;
    color: rgb(230, 165, 26);
    font-weight: 600;
    text-align: center;
  }
  
  

  
  
  

  
 
  p{
      margin: 10px;
      padding: 10px;
  }
  
  .desc {
    margin: 1em 0.75em 0 0;
    border-radius: 50%;
    border: 2px solid white;
  box-shadow:
     /* inner right cyan broad */
    0 0 50px #fff,            /* outer white */
    -10px 0 80px #f0f,        /* outer left magenta */
    10px 0 80px #0ff;   
    color: white;
    font-size: 0.77777em;
    text-align: center;
    border-radius: 20px;
    font-style: italic;
    line-height: 1.5em;
    
  }
  
  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
  
  /* ================ Timeline Media Queries ================ */

  @media screen and (max-width: 1515px) {
  

    .direction-l {
      position: relative;
      width: 46.9%;
      font-size: 32px;
      float: left;
      text-align: right;
    }
    
    .direction-r {
      position: relative;
      width: 46.9%;
      margin-left: 5px;
      font-size: 32px;
      float: right;
      
    }
  }
  @media screen and (max-width: 1211px) {
    .direction-l {
      position: relative;
      width: 46.9%;
      font-size: 32px;
      float: left;
      text-align: right;
    }
    
    .direction-r {
      position: relative;
      width: 46.9%;
      margin-left: 5px;
      font-size: 32px;
      float: right;
      
    }

  
  .direction-l,
  .direction-r {
      float: none;
      width: 99%;
  
      text-align: center;
  }
  
  .fla-wrapper {
      text-align: center;
  }
  
  .fla {
      background: rgb(255,255,255);
      z-index: 15;
  }
  

  .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
  }
  
  .direction-l .time-wrapper {
      float: none;
  }
  
  .direction-r .time-wrapper {
      float: none;
  }
  
  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;
      box-shadow:
      inset 0 0 50px #fff,      /* inner white */
      inset 20px 0 80px #f0f,   /* inner left magenta short */
      inset -20px 0 80px #0ff,  /* inner right cyan short */
      inset 20px 0 300px #f0f,  /* inner left magenta broad */
      inset -20px 0 300px #0ff, /* inner right cyan broad */
      0 0 50px #fff,            /* outer white */
      -10px 0 80px #f0f,        /* outer left magenta */
      10px 0 80px #0ff; 
    
    z-index: 15;
  }
  
  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }
  
  }
  
