summary {
    font-size: 1.25rem;
    font-weight: 600;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid white;
    text-align: left;
    font-size: 30px;
    cursor: pointer;
    position: relative;
  }
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }
  .faq
  {
      width: 60%;
  }
  .outfaq
  {
      width: 100%;
      text-align: center;
      display: flex;justify-content: center;
  }
 .answer
 {
     color: white;
     font-size: 25px;
 }
 .heading
 {
     width: 100%;
     text-align: center;
     color: white;
 }